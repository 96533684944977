import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import BellAlertIcon from "../../../components/Icons/Bell-Alert";
import SignalSlash from "../../../components/Icons/Signal-Slash";
import CheckCircle from "../../../components/Icons/Check-Circle";
import PauseCircle from "../../../components/Icons/Pause-Circle";

import {
	Button,
	Container,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Typography,
} from "@material-ui/core";
import axios from "../../../axios";
import Page from "../../../components/Page";

// import Header from "./Header";
import Edit from "@material-ui/icons/Edit";

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{ id: "customerNumber", numeric: false, disablePadding: false, label: "Kundenummer" },
	{ id: "installationNumber", numeric: true, disablePadding: false, label: "Installation" },
	{ id: "isBWT", numeric: true, disablePadding: false, label: "Status" },
	{ id: "faultCode", numeric: true, disablePadding: false, label: "Fejlkode" },
	{ id: "lostConnection", numeric: true, disablePadding: false, label: "Mistet forbindelse" },
	{ id: "location", numeric: true, disablePadding: false, label: "Lokation" },
	{ id: "actions", numeric: true, disablePadding: false, label: "Handlinger" },
];

function EnhancedTableHead(props) {
	const { classes, order, orderBy, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "default"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	paper: {
		width: "100%",
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	BellAlertIcon: {
		width: "15px",
		height: "15px",
		color: "red",
	},
	Icons: {
		width: "20px",
		height: "20px",
	},
	IconsRed: {
		width: "20px",
		height: "20px",
		color: "red",
	},
	IconsGreen: {
		width: "20px",
		height: "20px",
		color: "green",
	},
	filterBtn: {
		backgroundColor: "black",
	},
}));

export default function EnhancedTable() {
	const classes = useStyles();
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("calories");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(50);
	const [installations, setInstallations] = useState([]);
	
    useEffect(() => {
        axios
			.get(`/api/electrolysis/installation/clients`)
			.then((response) => {
				setInstallations(response.data.installations);

			})
			.catch((error) => {
				console.log(error);
			});
    }, []);

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = installations.map((n) => n.id);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<Page className={classes.root}>
			{/* <Container maxWidth={false}> */}
			<Container>
				<Grid alignItems="flex-end" container justify="space-between" spacing={3}>
					<Grid item>
						<Typography component="h1" variant="h3">
							Alle installationer
						</Typography>
						<Typography component="h2" gutterBottom variant="overline">
							Elektrolysestyringer
						</Typography>
					</Grid>
				</Grid>

				<Paper className={classes.paper}>
					{/* <EnhancedTableToolbar numSelected={selected.length} /> */}
					<TableContainer>
						<Table className={classes.table} size="small" aria-label="enhanced table">
							<EnhancedTableHead
								classes={classes}
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={installations.length}
							/>
							<TableBody>
								{stableSort(installations, getComparator(order, orderBy))
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) => {
										const labelId = `enhanced-table-checkbox-${index}`;

										return (
											<TableRow hover onClick={(event) => handleClick(event, row.id)} tabIndex={-1} key={row.id}>
												<TableCell id={labelId} scope="row">
													{row.customerNumber}
												</TableCell>
												<TableCell align="right">{row.installationNumber}</TableCell>
												<TableCell align="right">
													{row.isBWT == 1 ? <CheckCircle className={classes.IconsGreen} /> : <PauseCircle className={classes.Icons} />}
												</TableCell>
												<TableCell align="right">
													{row.faultCode > 0 ? (
														<>
															<BellAlertIcon className={classes.BellAlertIcon} /> {row.faultCode}
														</>
													) : null}
												</TableCell>
												<TableCell align="right">
													{row.lostConnection > 0 ? (
														<>
															<SignalSlash className={classes.BellAlertIcon} /> {row.lostConnection}
														</>
													) : null}
												</TableCell>
												<TableCell align="right">{row.location}</TableCell>
												<TableCell align="right">
													<Button color="primary" component={RouterLink} size="small" to={"/client/installation/" + row.id}>
														<Edit fontSize="small" />
													</Button>
												</TableCell>
											</TableRow>
										);
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						labelRowsPerPage="Rækker per side"
						rowsPerPageOptions={[25, 50, 100, 250]}
						component="div"
						count={installations.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
					/>
				</Paper>
			</Container>
		</Page>
	);
}
